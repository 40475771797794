import React from "react";
import cx from "classnames";
import { Reveal } from "src/components/reveal";
import { useMediaQuery } from "@material-ui/core/";

import { ReactComponent as ArrowRight } from "src/images/svg/ArrowRight.svg";

import * as styles from "./locations.module.css";

interface WhoWeAreLocationsProps {
	data: {
		locations: [
			{
				_key: string;
				title: string;
				location: string;
			}
		];
	};
}

export const WhoWeAreLocations = ({ data }: WhoWeAreLocationsProps) => {
	const isLarge = useMediaQuery("(min-width: 1024px)");
	const { locations } = data;

	return (
		<div className={cx("double-border-top", styles.locations)}>
			<div className={styles.locationsInner}>
				{locations &&
					locations.map((location, index) => (
						<Reveal
							className={styles.location}
							delay={index * 250}
							key={location._key}
						>
							<ArrowRight className="mr05" />
							<p className={cx("small-body")}>
								{location.title}
								{isLarge ? <br /> : " "}
								{location.location}
							</p>
						</Reveal>
					))}
			</div>
		</div>
	);
};
