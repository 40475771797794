import React from "react";
import cx from "classnames";
import ReactPlayer from "react-player/vimeo";
import { Reveal } from "src/components/reveal";
import { BlockContent } from "src/components/block-content";
import { Image } from "src/components/image";
import { ShadowBorder } from "src/components/shadow-border";
import { useInView } from "react-intersection-observer";

import { ReactComponent as GiantTwelve } from "src/images/svg/GiantTwelve.svg";

import * as styles from "./founder.module.css";

interface WhoWeAreFounderProps {
	data: { title: string; copy: any[]; portrait: any };
}

export const WhoWeAreFounder = ({ data }: WhoWeAreFounderProps) => {
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0,
	});

	const { title, copy, portrait } = data;
	return (
		<div className={cx("double-border-top", styles.founder)}>
			<h2 className={cx("title", styles.title)}>{title}</h2>
			<div className={styles.row}>
				<div className={styles.portraitContainer}>
					<ShadowBorder>
						<div className={styles.portraitInner}>
							<Image
								className={styles.portrait}
								alt={portrait.alt || title}
								asset={portrait.asset}
							/>
						</div>
					</ShadowBorder>
				</div>
				<div ref={ref}>
					<BlockContent
						blocks={copy}
						className={cx("small-body", styles.copy)}
					/>
				</div>
			</div>
			<div className={cx(styles.giantTwelve, inView ? styles.inView : "")}>
				<GiantTwelve />
			</div>
		</div>
	);
};
