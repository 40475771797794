import React, { useState } from "react";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Collapse, useMediaQuery } from "@material-ui/core";

import { ReactComponent as ArrowRight } from "src/images/svg/ArrowRight.svg";
import { ReactComponent as Plus } from "src/images/svg/Plus.svg";
import { ReactComponent as PlusLarge } from "src/images/svg/PlusLarge.svg";

import * as styles from "./accordion.module.css";

const MobileAccordionSection = ({
	section,
	expandedByDefault = false,
}: {
	section: AccordionSection;
	expandedByDefault: boolean;
}) => {
	const [expanded, setExpanded] = useState(expandedByDefault);
	return (
		<li className={cx(styles.mobileAccordionSection)}>
			<div>
				<button
					className={cx(
						"reset",
						styles.mobileTitleButton,
						expanded && styles.expanded
					)}
					onClick={() => setExpanded(!expanded)}
				>
					<div className={styles.mobileTitleButtonImgContainer}>
						<img
							width="15"
							src={section.titleSvg.asset.url}
							alt={section.titleSvg.alt}
							className={styles.mobileTitleButtonImg}
						/>
					</div>
					<Plus className={cx("hide-min-medium", styles.plus)} />
					<PlusLarge className={cx("hide-small", styles.plus)} />
				</button>
				<Collapse in={expanded}>
					<div className={styles.mobilePanelContent}>
						{section.bulletPoints && (
							<ul className={styles.pointsList}>
								{section.bulletPoints.map((point, index) => (
									<li className={styles.pointsItem} key={index}>
										<ArrowRight className={styles.pointsItemArrow} />
										<span className={cx("body", styles.pointsItemCopy)}>
											{point}
										</span>
									</li>
								))}
							</ul>
						)}
					</div>
				</Collapse>
			</div>
		</li>
	);
};

interface AccordionSection {
	_key: string;
	bulletPoints: string[];
	titleSvg: {
		alt: string;
		asset: any;
	};
}

export interface CapabilitiesAccordionProps {
	sections: [AccordionSection];
}

export const CapabilitiesAccordion = ({
	data,
}: {
	data: CapabilitiesAccordionProps;
}) => {
	const { sections } = data;
	const [activeIndex, setActiveIndex] = useState(0);
	const isDesktop = useMediaQuery("(min-width: 1024px)");

	const handleClick = (index: number) => {
		setActiveIndex(index);
	};

	return (
		<div className={styles.accordion}>
			{isDesktop ? (
				<ul className={styles.accordionList}>
					{sections &&
						sections.map((section, index) => (
							<li
								key={section._key}
								className={cx(
									styles.accordionPanel,
									activeIndex === index ? styles.open : ""
								)}
							>
								<div className={styles.panelInner}>
									<button
										className={cx("reset", styles.titleButton)}
										onClick={() => handleClick(index)}
									>
										<img
											width="28"
											src={section.titleSvg.asset.url}
											alt={section.titleSvg.alt}
										/>
									</button>
									<AnimatePresence>
										{activeIndex === index && (
											<motion.div
												initial={{ opacity: 0 }}
												animate={{ opacity: 1 }}
												exit={{ opacity: 0 }}
												className={styles.panelContent}
											>
												{section.bulletPoints && (
													<ul className={styles.pointsList}>
														{section.bulletPoints.map((point, index) => (
															<li className={styles.pointsItem} key={index}>
																<ArrowRight
																	className={styles.pointsItemArrow}
																/>
																<span
																	className={cx("body", styles.pointsItemCopy)}
																>
																	{point}
																</span>
															</li>
														))}
													</ul>
												)}
											</motion.div>
										)}
									</AnimatePresence>
								</div>
							</li>
						))}
				</ul>
			) : (
				<ul>
					{sections &&
						sections.map((section, index) => (
							<MobileAccordionSection
								key={section._key}
								section={section}
								expandedByDefault={index === 0}
							/>
						))}
				</ul>
			)}
		</div>
	);
};
