import React from "react";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";
import { MetaInfo } from "src/types/meta";
import { WhoWeAreIntro } from "src/components/who-we-are/intro";
import { WhoWeAreLocations } from "src/components/who-we-are/locations";
import { WhoWeArePeople } from "src/components/who-we-are/people";
import { WhoWeAreFounder } from "src/components/who-we-are/founder";
import { WhoWeAreTicker } from "src/components/who-we-are/ticker";
import { WhoWeAreAwardsPress } from "src/components/who-we-are/awards-press";
import { WhoWeAreCertifications } from "src/components/who-we-are/certifications";
import { WhoWeAreCommunity } from "src/components/who-we-are/community";

export interface WhoWeAreProps {
	data: {
		main: {
			slug: {
				current: string;
			};
			title: string;
			intro: {
				backgroundImage: {
					asset: {};
				};
				headline: any[];
				cards: any[];
			};
			locations: {
				locations: [{ _key: string; title: string; location: string }];
			};
			people: {
				headline: any[];
				copy: any[];
				videoEmbed: {
					title?: string;
					url: string;
				};
			};
			founder: {
				title: string;
				copy: any[];
				portrait: any;
			};
			ticker: {
				items: string[];
			};
			awardsPress: {
				media: any;
				list: [{ _key: string; copy: any[] }];
				title: string;
			};
			certifications: {
				certifications: [
					{
						_key: string;
						png: {
							alt?: string;
							asset: any;
						};
						url?: string;
					}
				];
			};
			community: {
				title: string;
				copy: any[];
				additionalCopy: any[];
				headline: any[];
				link: any;
				numbers: {
					finePrint: string;
					numbers: [];
				};
			};
		};
		meta: {
			metaTitle?: string;
			metaDescription?: string;
			openImage?: any;
			twitterImage?: any;
		};
	};
	location: any;
}

const WhoWeAreTemplate = ({ location, data }: WhoWeAreProps) => {
	const { main, meta } = data;

	// set fallback meta tags from content
	const metaInfo: MetaInfo = {
		metaTitle: meta?.metaTitle || main.title,
		metaDescription: meta?.metaDescription,
		openImage: meta?.openImage || main.intro?.backgroundImage,
		twitterImage:
			meta?.twitterImage || meta?.openImage || main.intro?.backgroundImage,
	};

	return (
		<RevealOnMount location={location}>
			<SEO
				metaInfo={metaInfo}
				pagePath={location?.pathname}
				slug={main.slug.current}
			/>
			<WhoWeAreIntro data={main.intro} />
			<div className="bg-cream z1">
				<WhoWeAreLocations data={main.locations} />
				<WhoWeArePeople data={main.people} />
				<WhoWeAreFounder data={main.founder} />
				<WhoWeAreTicker data={main.ticker} />
				<WhoWeAreAwardsPress data={main.awardsPress} />
				<WhoWeAreCertifications data={main.certifications} />
				<WhoWeAreCommunity data={main.community} />
			</div>
		</RevealOnMount>
	);
};

export default WhoWeAreTemplate;
