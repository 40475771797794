import React from "react";
import cx from "classnames";
import { useInView } from "react-intersection-observer";
import { Media, MediaInterface } from "src/components/media";

import { ReactComponent as WeDoItAllGraphic } from "src/images/svg/WeDoItAll.svg";

import * as styles from "./hero.module.css";

interface HeroProps {
	data: {
		media: MediaInterface;
	};
}

export const CapabilitiesHero = ({ data }: HeroProps) => {
	const { ref, inView } = useInView({
		rootMargin: "400px 0px",
	});

	const { media } = data;

	return (
		<div ref={ref} className={cx("ac c-white", styles.hero)}>
			{inView && <Media data={media} className={styles.backgroundMedia} />}
			<WeDoItAllGraphic className={styles.graphic} />
		</div>
	);
};
