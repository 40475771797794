import React, { useState } from "react";
import cx from "classnames";
import { Media } from "src/components/media";
import { BlockContent } from "src/components/block-content";
import { Collapse } from "@material-ui/core";
import { ReactComponent as PlusLarge } from "src/images/svg/PlusLarge.svg";
import { ReactComponent as Plus } from "src/images/svg/Plus.svg";

import * as styles from "./faq.module.css";

interface QuestionData {
	_key: string;
	question: string;
	answer?: any[];
}

export const Question = ({ data }: { data: QuestionData }) => {
	const [open, setOpen] = useState(false);

	return (
		<div className={styles.faq}>
			<button
				onClick={() => setOpen(!open)}
				className={cx(styles.question, open ? styles.open : "")}
			>
				<h4 className={cx("small-body bold", styles.questionCopy)}>
					{data.question}
				</h4>
				<PlusLarge className={cx("hide-small", styles.plus)} />
				<Plus className={cx("hide-min-medium", styles.plus)} />
			</button>
			<Collapse in={open}>
				{data.answer && (
					<BlockContent
						className={cx("small-body", styles.answer)}
						blocks={data.answer}
					/>
				)}
			</Collapse>
		</div>
	);
};

export interface CapabilitiesFAQData {
	bannerMedia: {
		image: any;
		video?: any;
	};
	headline: any[];
	faq: [QuestionData];
}

export const CapabilitiesFAQ = ({ data }: { data: CapabilitiesFAQData }) => {
	const { bannerMedia, headline, faq } = data;
	return (
		<div className={styles.faqSection}>
			<div className={styles.banner}>
				<Media data={bannerMedia} />
			</div>
			<div className={styles.inner}>
				<BlockContent
					blocks={headline}
					className={cx("ac headline", styles.headline)}
				/>
				{faq && (
					<div className={styles.questions}>
						{faq.map((question) => (
							<Question data={question} key={question._key} />
						))}
					</div>
				)}
			</div>
		</div>
	);
};
