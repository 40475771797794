import React from "react";
import cx from "classnames";
import { Media } from "src/components/media";
import { BlockContent } from "src/components/block-content";
import { Reveal } from "src/components/reveal";
import { ReactComponent as ArrowRight } from "src/images/svg/ArrowRight.svg";

import * as styles from "./awards-press.module.css";

interface WhoWeAreAwardsPressProps {
	data: {
		media: any;
		list: [{ _key: string; copy: any[] }];
		title: string;
	};
}

export const WhoWeAreAwardsPress = ({ data }: WhoWeAreAwardsPressProps) => {
	const { title, list, media } = data;
	return (
		<div className={styles.awardsPress}>
			<div className={cx("bg-cream", styles.copy)}>
				<h2 className={cx("title", styles.title)}>{title}</h2>

				{list && (
					<ul>
						{list.map((item, index) => (
							<li className={styles.listItem} key={item._key}>
								<Reveal delay={index * 100}>
									<ArrowRight className={cx("mr05", styles.arrow)} />
									<BlockContent
										className={cx("small-body", styles.listItemCopy)}
										blocks={item.copy}
									/>
								</Reveal>
							</li>
						))}
					</ul>
				)}
			</div>
			<div className={cx("hide-max-medium", styles.mediaContainer)}>
				<Media className={styles.media} data={media} />
			</div>
		</div>
	);
};
