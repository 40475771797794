// extracted by mini-css-extract-plugin
export var accordion = "accordion-module--accordion--31Wj6";
export var accordionList = "accordion-module--accordionList--23sMf";
export var accordionPanel = "accordion-module--accordionPanel--Kr0GY";
export var open = "accordion-module--open--3qf67";
export var panelInner = "accordion-module--panelInner--39mwu";
export var titleButton = "accordion-module--titleButton--3L_8k";
export var panelContent = "accordion-module--panelContent--Xpg_h";
export var pointsList = "accordion-module--pointsList--2qMVy";
export var pointsItem = "accordion-module--pointsItem--jxPU7";
export var pointsItemArrow = "accordion-module--pointsItemArrow--25FMa";
export var pointsItemCopy = "accordion-module--pointsItemCopy--3K6G8";
export var mobileAccordionSection = "accordion-module--mobileAccordionSection--KttrF";
export var mobileTitleButton = "accordion-module--mobileTitleButton--218wZ";
export var mobileTitleButtonImgContainer = "accordion-module--mobileTitleButtonImgContainer--22QEa";
export var mobileTitleButtonImg = "accordion-module--mobileTitleButtonImg--1VqTH";
export var plus = "accordion-module--plus--16glY";
export var expanded = "accordion-module--expanded--1oSnd";
export var mobilePanelContent = "accordion-module--mobilePanelContent--3qIa5";