import React from "react";
import cx from "classnames";
import { Reveal } from "src/components/reveal";
import { BlockContent } from "src/components/block-content";
import { Image } from "src/components/image";
import { PartnershipCarousel } from "src/components/partnership-carousel";
import { ArrowLink } from "src/components/arrow-link";

import * as styles from "./community.module.css";

interface WhoWeAreCommunityProps {
	data: {
		headline: any[];
		copy: any[];
		numbers: {
			title: string;
			finePrint: string;
			numbers: [
				{
					_key: string;
					number: {
						alt: string;
						asset: any;
					};
					caption: string;
				}
			];
		};
		partners: {
			title: string;
			partners: any[];
		};
		additionalCopy: any[];
		link: any;
	};
}

export const WhoWeAreCommunity = ({ data }: WhoWeAreCommunityProps) => {
	const { headline, copy, numbers, partners, additionalCopy, link } = data;
	return (
		<div className={cx("double-border-top", styles.community)}>
			<div className={styles.container}>
				<BlockContent
					blocks={headline}
					className={cx("headline", styles.headline)}
				/>

				{copy && (
					<BlockContent blocks={copy} className={cx("title", styles.copy)} />
				)}

				{numbers && (
					<div className={styles.numbers}>
						<h3 className={cx("title", styles.numbersTitle)}>
							{numbers.title}
						</h3>
						<span className={cx("caption")}>{numbers.finePrint}</span>
						{numbers.numbers && (
							<ul className={styles.numbersList}>
								{numbers.numbers &&
									numbers.numbers.map((item, index) => (
										<li className={styles.numbersItem} key={item._key}>
											<Reveal delay={index * 100}>
												<div
													className={styles.numbersItemImageContainer}
													style={
														{
															"--ar":
																item.number.asset.metadata.dimensions
																	.aspectRatio,
														} as React.CSSProperties
													}
												>
													<Image
														className={styles.numbersItemImage}
														asset={item.number.asset}
														alt={item.number.alt}
													/>
												</div>
												<p className={cx("small-body")}>{item.caption}</p>
											</Reveal>
										</li>
									))}
							</ul>
						)}
					</div>
				)}
			</div>
			{partners && (
				<div>
					<h2 className={cx("title", styles.partnersTitle)}>
						{partners.title}
					</h2>
					{partners?.partners && <PartnershipCarousel data={partners} />}
				</div>
			)}
			<div className={styles.container}>
				{additionalCopy && (
					<BlockContent
						blocks={additionalCopy}
						className={cx("body", styles.additionalCopy)}
					/>
				)}
				{link && (
					<ArrowLink
						className={styles.link}
						link={link}
						type={link.linkType}
						copy={link.linkCopy}
					/>
				)}
			</div>
		</div>
	);
};
