import React from "react";
import cx from "classnames";
import { Image } from "src/components/image";
import { Reveal } from "src/components/reveal";
import { ConditionalWrapper } from "src/components/conditional-wrapper";

import { ReactComponent as ArrowRight } from "src/images/svg/ArrowRight.svg";

import * as styles from "./certifications.module.css";

interface WhoWeAreCertificationsProps {
	data: {
		certifications: [
			{
				_key: string;
				png: {
					alt?: string;
					asset: any;
				};
				url?: string;
			}
		];
	};
}

export const WhoWeAreCertifications = ({
	data,
}: WhoWeAreCertificationsProps) => {
	const { certifications } = data;

	return (
		<div className={cx("double-border-top", styles.certifications)}>
			<div className={cx("hide-scrollbar", styles.certificationsInner)}>
				{certifications &&
					certifications.map((item, index) => (
						<Reveal key={item._key} delay={index * 100}>
							<ConditionalWrapper
								condition={item.url ? true : false}
								wrapper={(children) => (
									<a
										href={item.url}
										target="_blank"
										rel="noopener noreferrer"
										aria-label={item.png.alt}
									>
										{children}
									</a>
								)}
							>
								<div
									className={styles.item}
									style={
										{
											"--ar": item.png.asset.metadata.dimensions.aspectRatio,
										} as React.CSSProperties
									}
								>
									<Image
										className={styles.png}
										imageId={item.png.asset._id}
										alt={item.png.alt || ""}
										gatsbyImageClassName={styles.gatsbyImage}
										objectFit="contain"
										width={240}
									/>
								</div>
							</ConditionalWrapper>
						</Reveal>
					))}
			</div>
		</div>
	);
};
