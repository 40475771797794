import React from "react";
import cx from "classnames";
import { useInView } from "react-intersection-observer";
import { Container } from "src/components/container";
import { BlockContent } from "src/components/block-content";
import { Image } from "src/components/image";
import { Reveal } from "src/components/reveal";

import * as styles from "./intro.module.css";

const Card = ({ data }: { data: any }) => {
	const { ref, inView } = useInView({
		rootMargin: "0px 0px",
	});
	return (
		<Reveal className={styles.card}>
			<div ref={ref}>
				<h3 className={cx("title mb1", styles.cardTitle)}>{data.title}</h3>
				<BlockContent
					className={cx(
						"body my1 highlight-em",
						inView ? "in-view" : "",
						styles.cardCopy
					)}
					blocks={data.copy}
				/>
			</div>
		</Reveal>
	);
};

interface WhoWeAreIntroProps {
	data: {
		backgroundImage: {
			asset: {};
		};
		headline: any[];
		cards: any[];
	};
}

export const WhoWeAreIntro = ({ data }: WhoWeAreIntroProps) => {
	const { ref, inView } = useInView({
		rootMargin: "400px 0px",
	});

	const { backgroundImage, headline, cards } = data;
	return (
		<div ref={ref}>
			{inView && (
				<Image
					asset={data.backgroundImage.asset}
					alt="Who we are"
					className={styles.backgroundImage}
				/>
			)}
			{/* <Container> */}
			<h1 className={styles.headline}>
				<BlockContent className={cx("display c-cream")} blocks={headline} />
			</h1>
			<div className={styles.cards}>
				{cards && cards.map((card) => <Card data={card} key={card._key} />)}
			</div>
			{/* </Container> */}
		</div>
	);
};
