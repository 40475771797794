// extracted by mini-css-extract-plugin
export var community = "community-module--community--19bd-";
export var container = "community-module--container--20qeM";
export var headline = "community-module--headline--27cKV";
export var copy = "community-module--copy--_9Zbl";
export var numbers = "community-module--numbers--Xqo32";
export var numbersTitle = "community-module--numbersTitle--3nIie";
export var numbersList = "community-module--numbersList--2LbBb";
export var numbersItem = "community-module--numbersItem--2jNI6";
export var numbersItemImageContainer = "community-module--numbersItemImageContainer--347Ve";
export var numbersItemImage = "community-module--numbersItemImage--10I-a";
export var partnersTitle = "community-module--partnersTitle--3qS2j";
export var additionalCopy = "community-module--additionalCopy--IovGi";
export var link = "community-module--link--1iSiE";