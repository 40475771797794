import React from "react";
import cx from "classnames";
import { Marquee } from "src/components/marquee";

import * as styles from "./ticker.module.css";

interface WhoWeAreTickerProps {
	data: {
		items: string[];
	};
}

export const WhoWeAreTicker = ({ data }: WhoWeAreTickerProps) => {
	const { items } = data;

	return (
		<div className={styles.ticker}>
			<Marquee>
				<>
					{items?.map((item, index) => (
						<div className={styles.item} key={index}>
							<span key={index} className={cx("lcd-small")}>
								{item}
							</span>
						</div>
					))}
				</>
			</Marquee>
		</div>
	);
};
