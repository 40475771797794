// extracted by mini-css-extract-plugin
export var faqSection = "faq-module--faqSection--28ybf";
export var inner = "faq-module--inner--1WuAV";
export var banner = "faq-module--banner--1qziz";
export var headline = "faq-module--headline--2cbl2";
export var questions = "faq-module--questions--1P7D2";
export var faq = "faq-module--faq--1G8s6";
export var question = "faq-module--question--11nUm";
export var questionCopy = "faq-module--questionCopy--2ljti";
export var plus = "faq-module--plus--1FKEX";
export var open = "faq-module--open--MoHrv";
export var answer = "faq-module--answer--1sAxY";