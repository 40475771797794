import React, { useState, useEffect } from "react";
import { Router } from "@reach/router";
import { Loading } from "src/components/loading";
import Homepage from "src/templates/homepage";
import Clients from "src/templates/clients";
import WhoWeAre from "src/templates/who-we-are";
import Capabilities from "src/templates/capabilities";
import Sustainability from "src/templates/sustainability";
import EMEA from "src/templates/emea";
import Contact from "src/templates/contact";
import TextPage from "src/templates/textPage";
import Project from "src/templates/project";

import {
	homepageQuery,
	clientsQuery,
	whoWeAreQuery,
	capabilitiesQuery,
	sustainabilityQuery,
	EMEAQuery,
	contactQuery,
	projectQuery,
	pageQuery,
} from "src/api/previewQueries";

import client from "src/api/sanityPreviewClient";

const PreviewPage = ({ documentId }: { documentId: string }) => {
	const [pageType, setPageType] = useState<string | null>(null);
	const [pageData, setPageData] = useState<any | null>(null);
	const [subscription, setSubscription] = useState(null);
	const [query, setQuery] = useState<string | null>(null);

	const queryType = `*[_id == "${documentId}"] {
			...,
		}`;

	const queryPreviewProject = `*[_id == "${documentId}" ] {
			${projectQuery}
		}`;

	const queryPreviewHomepage = `*[_id == "${documentId}" ] {
			${homepageQuery}
		}`;

	const queryPreviewClients = `*[_id == "${documentId}" ] {
			${clientsQuery}
		}`;

	const queryPreviewWhoWeAre = `*[_id == "${documentId}" ] {
			${whoWeAreQuery}
		}`;

	const queryPreviewSustainability = `*[_id == "${documentId}" ] {
			${sustainabilityQuery}
		}`;

	const queryPreviewEMEA = `*[_id == "${documentId}" ] {
			${EMEAQuery}
		}`;

	const queryPreviewCapabilities = `*[_id == "${documentId}" ] {
			${capabilitiesQuery}
		}`;

	const queryPreviewContact = `*[_id == "${documentId}" ] {
			${contactQuery}
		}`;

	const queryPreviewPage = `*[_id == "${documentId}" ] {
			${pageQuery}
		}`;

	// First set query by document type
	useEffect(() => {
		client
			.fetch(queryType)
			.then((response: any) => {
				console.log(response);
				setPageType(response[0]._type);
				switch (response[0]._type) {
					case "homepage":
						setQuery(queryPreviewHomepage);
						break;
					case "clients":
						setQuery(queryPreviewClients);
						break;
					case "whoWeAre":
						setQuery(queryPreviewWhoWeAre);
						break;
					case "capabilities":
						setQuery(queryPreviewCapabilities);
						break;
					case "sustainability":
						setQuery(queryPreviewSustainability);
						break;
					case "EMEA":
						setQuery(queryPreviewEMEA);
						break;
					case "contact":
						setQuery(queryPreviewContact);
						break;
					case "project":
						setQuery(queryPreviewProject);
						break;
					case "textPage":
						setQuery(queryPreviewPage);
						break;
					default:
						return;
				}
			})
			.catch((error) => {
				console.log("problem", error);
			});
	}, [documentId, queryPreviewProject, queryType]);

	// Then fetch document data
	useEffect(() => {
		if (query) {
			client
				.fetch(query)
				.then((response) => {
					setPageData(response[0]);
				})
				.catch((error) => {
					console.log("problem", error);
				});
		}
	}, [query]);

	// Finally, subscribe to query for live updates
	useEffect(() => {
		setSubscription(
			client.listen(query, {}, { includeResult: false }).subscribe((update) => {
				const timeout = setTimeout(() => {
					client.fetch(query).then((response) => {
						setPageData(response[0]);
					});
				}, 1000);
			})
		);

		// // Unsubscribe from query
		// return function unsubscribe() {
		// 	if (subscription) {
		// 		subscription.unsubscribe();
		// 	}
		// };
	}, [query]);

	if (pageData) {
		console.log(pageData);
		switch (pageType) {
			case "homepage":
				return <Homepage data={pageData.content} location={location} />;
			case "clients":
				return <Clients data={pageData.content} location={location} />;
			case "whoWeAre":
				return <WhoWeAre data={pageData.content} location={location} />;
			case "capabilities":
				return <Capabilities data={pageData.content} location={location} />;
			case "sustainability":
				return <Sustainability data={pageData.content} location={location} />;
			case "EMEA":
				return <EMEA data={pageData.content} location={location} />;
			case "contact":
				return <Contact data={pageData.content} location={location} />;
			case "textPage":
				return <TextPage data={{ page: pageData }} location={location} />;
			case "project":
				return <Project data={{ project: pageData }} />;
			default:
				break;
		}
	} else {
		return <Loading />;
	}
};

const Preview = () => {
	return (
		<Router>
			<PreviewPage path="/preview/:documentId" />
		</Router>
	);
};

export default Preview;
