import React from "react";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";
import { MetaInfo } from "src/types/meta";
import { CapabilitiesHero } from "src/components/capabilities/hero";
import {
	CapabilitiesIntro,
	CapabilitiesIntroProps,
} from "src/components/capabilities/intro-copy";
import {
	CapabilitiesAccordion,
	CapabilitiesAccordionProps,
} from "src/components/capabilities/accordion";
import {
	CapabilitiesFAQ,
	CapabilitiesFAQData,
} from "src/components/capabilities/faq";

export interface CapabilitiesProps {
	data: {
		main: {
			slug: {
				current: string;
			};
			title: string;
			hero: any;
			intro: CapabilitiesIntroProps;
			accordion: CapabilitiesAccordionProps;
			faq: CapabilitiesFAQData;
		};
		meta: {
			metaTitle?: string;
			metaDescription?: string;
			openImage?: any;
			twitterImage?: any;
		};
	};
	location: any;
}

const CapabilitiesTemplate = ({ location, data }: CapabilitiesProps) => {
	const { main, meta } = data;

	// set fallback meta tags from content
	const metaInfo: MetaInfo = {
		metaTitle: meta?.metaTitle || main.title,
		metaDescription: meta?.metaDescription,
		openImage: meta?.openImage || main.hero.media.image,
		twitterImage:
			meta?.twitterImage || meta?.openImage || main.hero.media.image,
	};

	return (
		<RevealOnMount location={location}>
			<SEO
				metaInfo={metaInfo}
				pagePath={location?.pathname}
				slug={main.slug.current}
			/>
			<CapabilitiesHero data={main.hero} />
			<div className="bg-cream z1">
				<CapabilitiesIntro data={main.intro} />
				<CapabilitiesAccordion data={main.accordion} />
				<CapabilitiesFAQ data={main.faq} />
			</div>
		</RevealOnMount>
	);
};

export default CapabilitiesTemplate;
