import React from "react";
import cx from "classnames";
import { BlockContent } from "src/components/block-content";
import { VideoCard } from "src/components/video-card";

import * as styles from "./people.module.css";

interface WhoWeArePeopleProps {
	data: {
		copy: any[];
		headline: any[];
		videoEmbed: {
			title?: string;
			url: string;
		};
	};
}

export const WhoWeArePeople = ({ data }: WhoWeArePeopleProps) => {
	const { headline, copy, videoEmbed } = data;
	return (
		<div className={cx("double-border-top", styles.people)}>
			{/* <Reveal>PEOPLE</Reveal> */}
			<BlockContent
				blocks={headline}
				className={cx("ac headline", styles.headline)}
			/>
			<BlockContent blocks={copy} className={cx("ac", styles.copy)} />
			{videoEmbed.url && (
				<VideoCard
					className={styles.videoCard}
					title={videoEmbed.title}
					url={videoEmbed.url}
				/>
			)}
		</div>
	);
};
