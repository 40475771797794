import React, { useEffect, useState, useCallback } from "react";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Container } from "src/components/container";
import { BlockContent } from "src/components/block-content";

import { useInView } from "react-intersection-observer";
import { useAutoplay } from "src/hooks/useAutoplay";

import * as styles from "./intro.module.css";

export interface CapabilitiesIntroProps {
	introCopy: any[];
	solutionsCopy: any[];
	solutionsSVGs: any[];
}

export const CapabilitiesIntro = ({
	data,
}: {
	data: CapabilitiesIntroProps;
}) => {
	const { introCopy, solutionsCopy, solutionsSVGs } = data;
	const { ref, inView } = useInView({
		threshold: 0.5,
	});

	const [activeSolutionIndex, setActiveSolutionIndex] = useState<number>(0);

	const cycleSolutions = useCallback(() => {
		setActiveSolutionIndex(
			activeSolutionIndex === solutionsSVGs.length - 1
				? 0
				: activeSolutionIndex + 1
		);
	}, [activeSolutionIndex, setActiveSolutionIndex]);

	const { play, stop } = useAutoplay(cycleSolutions, 2000);

	useEffect(() => {
		play();
	}, [play]);

	return (
		<div className={styles.intro} ref={ref}>
			<Container>
				<BlockContent
					className={cx("ac title highlight-em", inView ? "in-view" : "")}
					blocks={data.introCopy}
				/>
				<BlockContent
					className={cx("ac title", styles.solutionsCopy)}
					blocks={solutionsCopy}
				/>
				<div className={styles.solutions}>
					<AnimatePresence exitBeforeEnter>
						{solutionsSVGs &&
							solutionsSVGs.map((item, index) => {
								if (activeSolutionIndex != index) {
									return null;
								}
								return (
									<motion.div
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										key={`solution-${index}`}
										className={cx(styles.solution)}
									>
										<img
											src={item.asset.url}
											alt={item.alt}
											className={styles.solutionImg}
											height={60}
										/>
									</motion.div>
								);
							})}
					</AnimatePresence>
				</div>
			</Container>
		</div>
	);
};
